<template>
    <div class="push-configuration">
        <div class="push-configuration-container">
            <div class="push-configuration-container-top">
                <button v-show="currentComponent === 'display'" class="push-configuration-container-top-edit"
                    @click="onEditClick">
                    编辑
                </button>
            </div>
            <div class="push-configuration-container-bottom">

                <component :is="currentComponent" :model="settingInfoModel" @onCancelClick="onEditCancelClick"
                    @onSaveClick="onSaveClick" :canSwitch="currentComponent === 'display'"
                    :isCombineDateAndEvent="isCombineDateAndEvent" :isCombineSignAndPulse="isCombineSignAndPulse">
                </component>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {
        Display: () => import("@c/setting/push-configuration/display/display.vue"),
        Edit: () => import("@c/setting/push-configuration/edit/edit.vue")
    },
    data() {
        return {
            currentComponent: "display",
            rawSettingInfoModel: {},
            editedSettingInfoModel: {},
            isCombineSignAndPulse: false,
            isCombineDateAndEvent: false,
        };
    },
    computed: {
        hospitalID: function () {
            return this.$store.state.selectedHospital.hospitalid;
        },
        settingInfoModel() {
            const settingInfoModel = this.rawSettingInfoModel;
            return settingInfoModel;
        },
    },

    watch: {
        hospitalID: function () {
            this.currentComponent = "display";
            this.getPushInfo();
        },
    },
    created() {
        this.getPushInfo();
    },

    methods: {
        onEditClick() {
            if (this.currentComponent === "display") {
                this.currentComponent = "edit";
            } else {
                this.currentComponent = "display";
            }
        },

        onEditCancelClick() {
            this.currentComponent = "display";
        },

        onSaveClick(settingInfoModel) {
            this.editedSettingInfoModel = settingInfoModel;
            this.$emit("onSaveClick")
        },

        async getPushInfo() {
            try {
                const params = {
                    "hospitalId": this.hospitalID
                };
                const data = await this.$api.loadOrgSendSetup(params);
                this.rawSettingInfoModel = data;
            } catch (error) {
                this.isRequestLocked = false;
                this.$toast.showRed(error);
            }
        },

        async saveInfo() {

            try {
                let model = this.editedSettingInfoModel
                const params = {
                    "hospitalId": this.hospitalID,
                    "weixinWebhookUrl": model.weixinWebhookUrl,
                    "weixinWebhookFrequentType": model.weixinWebhookFrequentType,
                    "weixinWebhookSendPdf": model.weixinWebhookSendPdf,
                    "weixinWebhookSendText": model.weixinWebhookSendText,
                    "smsTemplateId": model.smsTemplateId,
                    "smsFrequentType": model.smsFrequentType
                };
                const _ = await this.$api.orgSendSetup(params);
                this.$toast.showGreen("推送配置成功");
                this.currentComponent = "display";
                this.getPushInfo();
            } catch (error) {
                this.isRequestLocked = false;
                this.$toast.showRed(error);
            }
        }
    }

}
</script>


<style lang="scss" scoped>
.push-configuration {
    background-color: white;
    border-radius: rem(2);
    width: 100%;
    height: 100%;

    &-container {

        height: 100%;
        overflow: hidden;
        // overflow-y: auto;
        box-sizing: border-box;
        padding: rem(0) rem(36) rem(50);

        &-top {
            width: 100%;
            height: rem(72);
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &-edit {
                color: #325FAC;
                font-size: rem(14);
                border-radius: rem(4);
                border: rem(1) solid #325FAC;
                width: rem(88);
                height: rem(32);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }



    &-bottom {
        width: 100%;

    }
}
</style>